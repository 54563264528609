export const socketURL = "wss://node-oscar.mobiloitte.com";
const url = "https://node-oscar.mobiloitte.com";

// // Puneet
// export const socketURL = "ws://172.16.1.223:1939";
// const url = "http://172.16.1.223:1939";

// // Ashu sir
// export const socketURL = "ws://172.16.2.248:1939";
// const url = "http://172.16.2.248:1939";

// // // Vishnu
// export const socketURL = "ws://172.16.1.132:1939";
// const url = "http://172.16.1.132:1939";

const user = `${url}/api/v1/user`;
const admin = `${url}/api/v1/admin`;

const stake = `${url}/api/v1/stake`;
const nft = `${url}/api/v1/nft`;

const updateSubscribe = `${url}/api/v1/updateSubscribe`;

const ApiConfig = {
  createNFT: `${nft}/createNFT`,
  listNFT: `${nft}/listNFT`,
  stakeNFT: `${stake}/stakeNFT`,
  stakeList: `${stake}/stakeList`,
  unstakeNFT: `${stake}/unstakeNFT`,

  userUpdateSubscribe: `${updateSubscribe}/userUpdateSubscribe`,
  // contact us

  addContactUs: `${user}/addContactUs`,
  connectWallet: `${user}/connectWallet`,

  updateProfile: `${user}/updateProfile`,
  getProfile: `${user}/getProfile`,
  buyToken: `${user}/buyToken`,
  buyTokenList: `${user}/buyTokenList`,
  graph: `${user}/graph`,
  buySubscriptionplan: `${user}/buySubscriptionplan`,
  dashboard: `${user}/dashboard`,
  addAutoTradingArbitrage: `${user}/addAutoTradingArbitrage`,

  // admin
  listSubscriptionPlan: `${admin}/listSubscriptionPlan`,
  addSubscriptionPlan: `${admin}/addSubscriptionPlan`,
  deleteSubscriptionPlan: `${admin}/deleteSubscriptionPlan`,
  buySubscriptionPlanAllList: `${admin}/buySubscriptionPlanAllList`,
  viewBuySubscriptionPlan: `${admin}/viewBuySubscriptionPlan`,
  viewSubscriptionPlan: `${admin}/viewSubscriptionPlan`,
  listToken: `${admin}/listToken`,
};
export default ApiConfig;
