import React, { useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  TextField,
  IconButton,
  Paper,
} from "@material-ui/core";
import {} from "react-feather";
import { Twitter } from "@material-ui/icons";
import TelegramIcon from "@material-ui/icons/Telegram";
import { SiMedium } from "react-icons/si";
import { BsFacebook } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { BsDiscord } from "react-icons/bs";
import Scroll from "react-scroll";
import { useHistroy } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ApiConfig from "src/config/APICongig";
import axios from "axios";
import { toast } from "react-toastify";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
const ScrollLink = Scroll.Link;
const useStyles = makeStyles((theme) => ({
  textFeild: {
    backgroundColor: "#2F2F2F",
    fontFamily: "'Ubuntu', sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "11px",
    "& hover": {
      borderRadius: "50px",
    },

    // paddingRight:'0px',
    // padding:"5px 15px 10px 15px",
    borderRadius: "50px",
    // 1borderRadius:"25px",
    "& input": {
      color: "white",
      // backgroundColor:"#2F2F2F"
      padding: "10px 0px 10px 15px",
    },
  },
  footerSection: {
    padding: "60px 0px 60px",
    "& p": {
      [theme.breakpoints.down("md")]: {
        fontSize: "11px !important",
      },
    },
  },

  menuButton: {
    fontSize: "16px",
    color: "rgba(255, 255, 255, 0.6)",
    cursor: "pointer",
    fontWeight: "300",
    display: "block",
    marginTop: "18px",
    lineHeight: "20px",
    "&.active": {
      color: "#E59446",
    },
    "&:hover": {
      color: "#E59446",
    },
    "&[tabIndex]:focus": {
      color: "#E59446",
    },
  },
  icons: {
    maxWidth: "250px",
    [theme.breakpoints.only("xs")]: {
      maxWidth: "430px",
    },
    // "& a": {
    //   padding: "20px 20px 0px 0px",
    // },
    "& svg": {
      fill: "currentColor",
      width: "1em",
      height: "1em",
      display: "inline-block",
      fontSize: "1.5rem",

      "&:hover": {
        color: "#E59446",
      },
    },
  },
  socialIcons: {
    // border: "1px solid #9b9be1",
    // borderRadius: "50%",
    // padding: "3px",
    "& svg": {
      color: "#9b9be1",
      "&:hover": {
        color: "#E59446",
      },
    },
  },

  bottomText: {
    paddingTop: "20px",
    [theme.breakpoints.only("xs")]: {
      justifyContent: "start",
    },
  },
  subscribetext: {
    "& p": {
      fontSize: "12px",
      margin: "20px 0 8px",
      color: "#fff",
    },
  },
}));

export default function Liquidity() {
  const history = useHistory();
  const classes = useStyles();

  const [email, setemail] = useState();
  const [Loader, setLoader] = useState();

  return (
    <>
      <Paper className={classes.footerSection}>
        <Container>
          <Grid container spacing={2} style={{ alignItem: "center" }}>
            <Grid item lg={4} md={4} sm={4} xs={12}>
              <Box className={classes.subscribetext}>
                <img
                  src="./images/contact.png"
                  alt="Logo"
                  width="100%"
                  style={{ maxWidth: "90px" }}
                />

                <Typography variant="body1">
                  {/* Subscribe for updates and announcements */}
                  Follow us on social media
                </Typography>
                <Box className={classes.icons}>
                  <IconButton
                    target="_blank"
                    href="https://www.facebook.com/"
                    className={classes.socialIcons}
                  >
                    <BsFacebook
                    // className={classes.socialIcons}
                    />
                  </IconButton>

                  <IconButton
                    target="_blank"
                    href="https://www.twitter.com/"
                    className={classes.socialIcons}
                  >
                    <Twitter
                    // className={classes.socialIcons}
                    />
                  </IconButton>

                  <IconButton
                    target="_blank"
                    href="https://www.telegram.com/"
                    className={classes.socialIcons}
                  >
                    <TelegramIcon
                    // className={classes.socialIcons}
                    />
                  </IconButton>

                  <IconButton
                    target="_blank"
                    href="https://www.telegram.com/"
                    className={classes.socialIcons}
                  >
                    <BsDiscord
                    // className={classes.socialIcons}
                    />
                  </IconButton>

                  <IconButton
                    target="_blank"
                    href="https://www.telegram.com/"
                    className={classes.socialIcons}
                  >
                    <AiFillYoutube
                    // className={classes.socialIcons}
                    />
                  </IconButton>
                  <IconButton
                    target="_blank"
                    href="https://www.telegram.com/"
                    className={classes.socialIcons}
                  >
                    <AiFillInstagram
                    // className={classes.socialIcons}
                    />
                  </IconButton>
                  <IconButton
                    target="_blank"
                    href="https://medium.com/log-in/"
                    className={classes.socialIcons}
                  >
                    <SiMedium
                    // className={classes.socialIcons}
                    />
                  </IconButton>

                  {/* <IconButton target="_blank" href="https://www.instagram.com/">
                    <Instagram className={classes.socialIcons} />
                  </IconButton> */}
                </Box>
              </Box>
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <Box>
                <Typography variant="h6">Features</Typography>
              </Box>

              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                to="about"
                onClick={() => {
                  history.push({
                    pathname: "/cross-exchange",
                  });
                }}
              >
                Automatic Trading
              </ScrollLink>
              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                to="about"
                onClick={() => {
                  history.push({
                    pathname: "/exchange",
                  });
                }}
              >
                Exchange Arbitrage
              </ScrollLink>
              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                to="about"
                onClick={() => {
                  history.push({
                    pathname: "/cross-exchange",
                  });
                }}
              >
                Market Making Bot
              </ScrollLink>
              {/* <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                to="about"
                onClick={() => {
                  history.push({
                    pathname: "/white-paper",
                  });
                }}
              >
                White Paper
              </ScrollLink> */}
              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                to="about"
                // onClick={() => {
                //   history.push({
                //     pathname: "/about",
                //   });
                // }}
              >
                Social Trading
              </ScrollLink>
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <Typography variant="h6"> Resources</Typography>

              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                style={{ cursor: "pointer" }}
                // onClick={() => {
                //   history.push({
                //     pathname: "/cross-exchange",
                //   });
                // }}
              >
                Get Started
              </ScrollLink>

              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                style={{ cursor: "pointer" }}
                // onClick={() => {
                //   history.push({
                //     pathname: "/terms",
                //   });
                // }}
              >
                Tutorials
              </ScrollLink>
              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                style={{ cursor: "pointer" }}
                // onClick={() => {
                //   history.push({
                //     pathname: "/terms",
                //   });
                // }}
              >
                Documentation
              </ScrollLink>
              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                style={{ cursor: "pointer" }}
                // onClick={() => {
                //   history.push({
                //     pathname: "/terms",
                //   });
                // }}
              >
                Academy
              </ScrollLink>
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={6}>
              <Typography variant="h6">Company</Typography>
              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                tabIndex="3"
                to="policy"
                onClick={() => history.push("/about")}
              >
                About Us
              </ScrollLink>
              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                tabIndex="3"
                to="policy"
                onClick={() => history.push("/about")}
              >
                Careers
              </ScrollLink>
              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                tabIndex="3"
                to="policy"
                onClick={() => history.push("/about")}
              >
                Press
              </ScrollLink>

              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                to="policy"
                onClick={() => {
                  history.push({
                    pathname: "/contact-us",
                  });
                }}
              >
                Contact Us
              </ScrollLink>
            </Grid>

            <Grid item lg={2} md={2} sm={4} xs={6}>
              <Typography variant="h6">Links</Typography>

              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                style={{ cursor: "pointer" }}
                // onClick={() => {
                //   history.push({
                //     pathname: "/Exchange",
                //   });
                // }}
              >
                Cryptocurrencies
              </ScrollLink>
              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push({
                    pathname: "/exchange",
                  });
                }}
              >
                Exchanges
              </ScrollLink>

              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                style={{ cursor: "pointer" }}
                // onClick={() => history.push("/?id=plan")}
              >
                Signals
              </ScrollLink>
              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/?id=plan")}
              >
                Pricing
              </ScrollLink>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </>
  );
}
