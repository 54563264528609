import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/index")),
  },
  //Exchange
  {
    exact: true,
    path: "/exchange",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Exchange")),
  },
  {
    exact: true,
    path: "/cross-exchange",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/CrossExchange/Index")),
  },

  {
    exact: true,
    path: "/cross-exchange-more",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/CrossExchangeMore/Index")),
  },
  {
    exact: true,
    path: "/white-paper",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/WhitePaper/WhitePaper")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },

  {
    exact: true,
    path: "/wallet",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Qrcode")),
  },

  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About Us/AboutUs")),
  },
  {
    exact: true,
    path: "/policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About Us/Policy")),
  },
  {
    exact: true,
    path: "/terms",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About Us/Terms")),
  },
  {
    exact: true,
    path: "/faqs",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About Us/FAQ")),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dasboard/Index")),
  },
  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile/Profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/EditProfile/EditProfile")),
  },
  {
    exact: true,
    path: "/plan",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Plan/PlanList")),
  },

  // {
  //   exact: true,
  //   path: "/chart",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dasboard/Chart")),
  // },
  {
    exact: true,
    path: "/bot-settings",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BotSettings/Index")),
  },
  {
    exact: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Notification/Notification")),
  },
  {
    exact: true,
    path: "/transaction",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Transactions/Transaction")),
  },
  {
    exact: true,
    path: "/subscription",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Subscriptions/Index")),
  },
  {
    exact: true,
    path: "/contact-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticPages/ContactUs")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
